export default {

	/**
	 * If Location Services is globally disabled.
	 * 
	 * @type {Boolean}
	 */
	disableLocationServices: false,

	/**
	 * Last saved record
	 * 
	 * @type {Object|null}
	 */
	recordLast: null,

	/**
	 * Pending (in-progress) record
	 *
	 * @type {Object|null}
	 */
	recordPending: null,

	/**
	 * Currently refreshing device information
	 *
	 * @type {Boolean}
	 */
	refreshing: false,

	/**
	 * Currently syncing
	 *
	 * @type {Boolean}
	 */
	syncing: false,

	/**
	 * Update available
	 *
	 * @type {String} Available version string
	 */
	update: null,

	/**
	 * Updated on this launch
	 *
	 * @type {Boolean}
	 */
	updated: false

};
