import Store from "App/Store.js";

/**
 * Location service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LocationService {

	/**
	 * Get the current location.
	 *
	 * @return {Object} `latitude`/`longitude`/`accuracy`
	 */
	static get() {

		/** Skip geolocation if Location Services is disabled */
		if (Store.getState().disableLocationServices) {
			return new Promise(resolve => {
				resolve({latitude: null, longitude: null, accuracy: null});
			});
		}

		return new Promise(resolve => {
			navigator.geolocation.getCurrentPosition(pos => {
				resolve({
					latitude: pos.coords.latitude,
					longitude: pos.coords.longitude,
					accuracy: pos.coords.accuracy
				});
			}, () => {
				resolve({latitude: null, longitude: null, accuracy: null});
			}, {enableHighAccuracy: false});
		});
	}

}

export default LocationService;
