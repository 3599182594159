export default {

	/**
	 * API URI
	 *
	 * @type {String}
	 */
	api: null,

	/**
	 * App version
	 * 
	 * @type {String|undefined}
	 */
	appv: (process.env.REACT_APP_APP || undefined),

	/**
	 * Camera ID
	 * 
	 * @type {String|Integer} String = device ID, Integer = first/second etc.
	 */
	camera: null,

	/**
	 * Camera stream mirror
	 * 
	 * @type {Boolean}
	 */
	cameraMirror: true,

	/**
	 * Dark mode
	 * 
	 * @type {Boolean}
	 */
	dark: false,


	/**
	 * If Location Services is globally disabled.
	 * 
	 * @type {Boolean}
	 */
	disableLocationServices: false,


	/**
	 * Feature flags
	 *
	 * @type {Array}
	 */
	flags: [],

	/**
	 * Registrations
	 * 
	 * @type {Array}
	 */
	registrations: [],

	/**
	 * Sync queue
	 * 
	 * @type {Array}
	 */
	sync: [],

	/**
	 * Last sync time
	 *
	 * @type {Integer}
	 */
	syncTime: null,

	/**
	 * Enable integrated touch keyboard
	 * 
	 * @type {Boolean}
	 */
	touchKeyboard: false

};
